<template>
    <modal name="CropperImageModal" :clickToClose='false' :width="width" :height="height" @opened="opened" @before-open="beforeOpen">
        <div class="modal">
            <div class="modal-title m-r-15">
                <img src="../../assets/icons/exit.svg" class="close m-t-15" @click="close(false)">
            </div>
            <div class="modal-inner">
                <div class="cropper--view">
                    <div class="cropper--container" ref="cropperContainer">

                    </div>
                    <div class="loader" v-if="status === 1">
                        <video-loader size="lg"></video-loader>
                    </div>
                </div>
                <div class="button--container">
                    <eye-pay-button-new size="xlg" color="black" rounded="rounded" value="ГОТОВО" @click.native="cropAndClose"></eye-pay-button-new>

                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Croppie from 'croppie/croppie'
import EyePayButtonNew from '@/components/button/EyePayButtonNew'
import VideoLoader from '@/components/loader/VideoLoader'

export default {
  name: 'CropperImageModal',
  components: {VideoLoader, EyePayButtonNew},
  props: {
    type: String
  },
  data () {
    return {
      croppie: null,
      loadedImage: null,
      status: 0,
      isMobileNew: (window.innerWidth < 960)

    }
  },
  computed: {
    width () {
      if (window.innerWidth > 960) {
        return 900
      } else {
        return '100%'
      }
    },

    height () {
      if (window.innerWidth > 960) {
        return 550
      } else {
        return 500
      }
    }
  },
  methods: {
    close () {
      this.$modal.hide('CropperImageModal')
      this.$emit('close')
    },
    // eslint-disable-next-line no-unused-vars
    opened (e) {
      this.status = 1
      const desktopWidth = (this.type === 'avatar') ? 320 : 400
      const desktopHeight = (this.type === 'avatar') ? 320 : 200

      const mobileWidth = (this.type === 'avatar') ? 200 : 280
      const mobileHeight = (this.type === 'avatar') ? 200 : 140

      const width = (this.isMobileNew) ? mobileWidth : desktopWidth
      const height = (this.isMobileNew) ? mobileHeight : desktopHeight
      const type = (this.type === 'avatar') ? 'circle' : 'square'

      var reader = new FileReader()

      if (this.loadedImage.type === 'image/jpeg') {
        this.getOrientation(this.loadedImage, (orientation) => {
          reader.onload = async (e) => {
            this.resetOrientation(e.target.result,orientation,(base64Image) => {
              this.loadCropper(width, height, type, base64Image)
            })
          }
          reader.readAsDataURL(this.loadedImage)
        })
      } else {
        reader.onload = async (e) => {
          this.loadCropper(width, height, type, e.target.result)
        }
        reader.readAsDataURL(this.loadedImage)
      }
    },

    loadCropper (width, height, type, res) {
      this.croppie = new Croppie(this.$refs.cropperContainer, {
        viewport: { width: width, height: height, type: type },
        showZoomer: false,
        enableOrientation: true,
        enableExif: true
      })

      this.croppie.bind({
        url: res,
      })


      this.status = 0
    },


    resetOrientation(srcBase64, srcOrientation, callback) {
      var img = new Image();

      img.onload = function() {
        var width = img.width,
          height = img.height,
          canvas = document.createElement('canvas'),
          ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
          case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, height, width); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
          default: break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        callback(canvas.toDataURL());
      };

      img.src = srcBase64;
    },

    async cropAndClose () {
      this.status = 1
      setTimeout(async () => {
        const res = await this.croppie.result({
          type: 'base64',
          size: 'original'
        })
        this.$emit('crop', res)
        this.$modal.hide('CropperImageModal')
        this.$emit('close')
        this.status = 0
      },500)

    },
    beforeOpen (e) {
      this.loadedImage = e.params.value
    },
    getOrientation (file, callback) {
      var reader = new FileReader()
      reader.onload = function (e) {
        var view = new DataView(e.target.result)
        if (view.getUint16(0, false) != 0xFFD8) {
          return callback(-2)
        }
        var length = view.byteLength; var offset = 2
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
          var marker = view.getUint16(offset, false)
          offset += 2
          if (marker == 0xFFE1) {
            if (view.getUint32(offset += 2, false) != 0x45786966) {
              return callback(-1)
            }

            var little = view.getUint16(offset += 6, false) == 0x4949
            offset += view.getUint32(offset + 4, little)
            var tags = view.getUint16(offset, little)
            offset += 2
            for (var i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little))
              }
            }
          } else if ((marker & 0xFF00) != 0xFF00) {
            break
          } else {
            offset += view.getUint16(offset, false)
          }
        }
        return callback(-1)
      }
      reader.readAsArrayBuffer(file)
    }
  }
}

</script>

<style scoped lang="scss">
    .modal{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: #292929;
        border-radius: 5px;

        border: 1px solid rgba(73,73,73,0.5);
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.50);
        z-index: 5;
    }

    .modal-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .modal-inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .cropper--view{
        width: 100%;
        height: calc(100% - 70px);
        display: flex;
        position: relative;
    }

    .cropper--container{
        width: 100%;
        height: 100%;
    }

    .close{
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-bottom: 15px;
    }

    .button--container{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .loader{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.75);

    }

</style>
