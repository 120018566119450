<template>
  <button type="button" class="eye-pay-btn" :class="[size,color,rounded]" :disabled="disabled">{{value}}</button>
</template>

<script>
export default {
  name: 'EyePayButtonNew',
  props: {
    value: String,
    disabled: Boolean,
    size: String,
    color: String,
    rounded: String
  }
}
</script>

<style scoped lang="scss">
button{
  padding: 0;
}

.eye-pay-btn{
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.50);
  border: 1px solid rgba(96,96,96,0.5);
  border-radius: 17.5px;
  padding-left: 26px;
  padding-right: 26px;
  height: 32px;
  background: #404040;
  cursor: pointer;
  letter-spacing: 0;
  margin: 0;
}

.lg{
  height: 36px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 18px;
  font-size: 16px;
}

.lg-s{
  height: 36px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 18px;
  font-size: 13px;
}

.md{
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
}

.xlg{
  padding-left: 50px;
  padding-right: 50px;
  height: 46px;
  font-size: 16px;
  font-weight: 300;
}

.black{
  background: #292929;
  border: 1px solid rgba(63,63,63,0.8);
  border-radius: 23px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.not-rounded{
  border-radius: 0px;
}

.eye-pay-btn:active{
  box-shadow: none;

}

.eye-pay-btn:hover{
  filter: brightness(87.5%);
}

.eye-pay-btn:disabled{
  opacity: 0.35;
  box-shadow: none;
  pointer-events: none;
}
</style>
