<template>
    <div class="group" :class="[size]">
        <p class="title">{{header}}</p>
        <input @input="$emit('input',$event.target.value)" :value="value" :id="id">
    </div>
</template>

<script>
    export default {
        name: "RegisterInput",
        props:{
            header: String,
            size: String,
          value: String,
          id: String
        }
    }
</script>

<style scoped lang="scss">
    .group{
        width: 270px;
        height: 51px;

      @include for-desktop-up{
        width: 180px;
        height: 34px;
      }

        &.lg{
            width: 375px;
            height: 51px;

          @include for-desktop-up{
            width: 250px;
            height: 34px;
          }

            input{
              width: 375px;
              height: 31px;

              @include for-desktop-up{
                width: 240px;
                height: 21px;
              }
            }
        }

        p{
            font-family: Roboto, "sans-serif";
            font-size: calc(16px - 4px);
            color: #2C2C2C;
            letter-spacing: 0;
            line-height: calc(19px - 4px);
            margin-left: 2px;


          @include for-desktop-up{
            font-size: 8px;
            line-height: 9px;
            margin-left: 1px;
          }
        }

        input{
            border: 1px solid #979797;
            border-radius: 8px;
            font-family: Roboto, "sans-serif";
            font-size: calc(14px - 2px);
            color: #2C2C2C;
            letter-spacing: 0;
            line-height: calc(16px - 2px);
            width: 270px;
            height: 31px;
            margin-top: 6px;
            background-color: transparent;
            padding-left: 10px;
            padding-right: 10px;
            outline: 0;


          @include for-desktop-up{
            font-size: 7px;
            padding-left: 5px;
            padding-right: 5px;
            width: 170px;
            height: 21px;
            line-height: 8px;
            margin-top: 3px;
            border-radius: 4px;

          }
        }
    }

    .not-valid{
      input{
        border-color: red;
      }
    }
</style>
